<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <validation-observer slim ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(onSubmit)">
        <v-card tile>
          <v-card-title class="pa-0">
            <v-toolbar flat dark color="primary">
              <v-btn icon dark @click="onClose">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Coordenador</v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-loading v-model="loading">
            <v-card-text>
              <v-container fluid class="px-0">
                <v-row align="center">
                  <v-col cols="auto">
                    <v-avatar size="60" color="grey">
                      <template v-if="form.profile.avatar.path">
                        <v-img :src="form.profile.avatar.path" />
                      </template>
                      <template v-else>
                        <span class="white--text text-uppercase" :style="{ fontSize: '30px' }">
                          {{ initials }}
                        </span>
                      </template>
                    </v-avatar>
                  </v-col>
                  <v-col cols="auto">
                    <AvatarUploader @success="onAvatarUploadSuccess">
                      Alterar Foto
                    </AvatarUploader>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-checkbox hide-details class="pt-0 mt-0" label="Habilitado" v-model="form.profile.active" />
                  </v-col>
                  <v-col cols="12">
                    <validation-provider vid="profile.name" v-slot="{ errors }">
                      <v-text-field dense outlined hide-details label="Nome" v-model="form.profile.name" />
                      <span class="red--text">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider vid="profile.gender" v-slot="{ errors }">
                      <v-select dense outlined hide-details label="Gênero" :items="genderLists" v-model="form.profile.gender" />
                      <span class="red--text">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider vid="profile.document" v-slot="{ errors }">
                      <v-text-field dense outlined hide-details type="text" label="CPF" v-model="form.profile.document" />
                      <span class="red--text">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider vid="email" v-slot="{ errors }">
                      <v-text-field dense outlined hide-details type="email" label="E-mail" v-model="form.email" />
                      <span class="red--text">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider vid="password" v-slot="{ errors }">
                      <v-text-field dense outlined hide-details obscore label="Senha" :type="typePassword" :append-icon="iconPassword" v-model="form.password" @click:append="toggleShowPassword" />
                      <span class="red--text">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="tertiary">
              <v-btn text @click="onClose">Fechar</v-btn>
              <v-spacer />
              <v-btn depressed :disabled="saving" :loading="saving" type="submit" color="primary">Salvar</v-btn>
            </v-card-actions>
          </v-loading>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import CoordinatorService from '../services/coordinator';
import AvatarUploader from '../components/AvatarUploader';

const initialForm = () => ({
  id: '',
  email: '',
  password: '',
  profile: {
    id: '',
    active: true,
    name: '',
    gender: '',
    document: '',
    initials: '',
    avatar: {
      name: '',
      path: '',
    },
  },
});

export default {
  props: ['value', 'id'],
  components: {
    AvatarUploader,
  },
  data: () => ({
    saving: false,
    loading: false,
    showPassword: false,
    genderLists: [
      { value: '', text: 'Selecione' },
      { value: '1', text: 'Masculino' },
      { value: '2', text: 'Feminino' },
    ],
    form: initialForm(),
  }),
  watch: {
    value(value) {
      if (value) {
        this.fetchRegister();
      }
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
      this.$emit('input', false);
    },
    onCopy() {
      this.$root.$emit('snackbar', {
        text: 'Código copiado.',
        color: 'green',
      });
    },
    onSubmit() {
      this.saving = true;

      CoordinatorService.save(this.form)
        .then(() => {
          this.$root.$emit('snackbar', {
            text: 'Dados salvos com sucesso.',
            color: 'green',
          });
          this.onClose();
        })
        .catch((data) => {
          this.$refs.form.setErrors(data.errors);
          this.$root.$emit('snackbar', {
            text: 'Não foi possível salvar os dados.',
            color: 'red',
          });
        })
        .then(() => {
          this.saving = false;
        });
    },
    onAvatarUploadSuccess(data) {
      this.form.profile.avatar = data;
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    fetchRegister() {
      this.loading = true;

      CoordinatorService.fetch(this.id)
        .then((register) => {
          this.form = register;
        })
        .catch(() => {
          this.form = initialForm();
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    initials() {
      const name = this.form.profile.name || '';
      const parts = name.trim().split(' ');

      if (parts.length === 1) {
        return name.substr(0, 2);
      }

      return (parts[0][0] || '') + (parts[1][0] || '');
    },
    iconPassword() {
      return this.showPassword ? 'mdi-eye-off' : 'mdi-eye';
    },
    typePassword() {
      return this.showPassword ? 'text' : 'password';
    },
  },
};
</script>
