<template>
  <v-container>
    <v-loading v-model="loading">
        <v-list-item class="px-2">
        <v-list-item-content>
          <v-list-item-title class="title">
            Coordenadores
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn fab depressed small color="primary" @click="onCreate">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-card outlined>
        <v-simple-table>
          <thead>
            <tr class="text-uppercase">
              <th colspan="100">Nome</th>
            </tr>  
          </thead>    
          <tbody>
            <template v-for="item in lists">
              <tr :key="item.id">
                <td>{{ item.name }}</td>
                <td class="text-right">
                  <v-btn icon @click="onEdit(item.id)">
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                  <v-btn icon :loading="deleting === item.id" :disabled="deleting === item.id" @click="onDelete(item.id)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-loading>
    <RegisterCoordinatorDialog :id="id" v-model="registerDialog" @close="fetchLists" />
  </v-container>
</template>

<script>
  import UserService from '../../services/user';
  import CoordinatorService from '../../services/coordinator';
  import RegisterCoordinatorDialog from '../../dialogs/RegisterCoordinatorDialog';

  export default {
    components: {
      RegisterCoordinatorDialog,
    },
    data: () => ({
      loading: false,
      deleting: false,
      registerDialog: false,
      id: null,
      lists: [],
    }),
    mounted() {
      this.fetchLists();
    },
    methods: {
      onEdit(id) {
        this.id = id;
        this.registerDialog = true;
      },
      onCreate() {
        this.id = null;
        this.registerDialog = true;
      },
      onDelete(id) {
        if (confirm('Deseja realmente remover este Coordenador?')) {  
          this.deleting = id;
          CoordinatorService.remove({id}).then(() => {
            this.fetchStart();
          }).catch(() => alert('Não foi possível remover o Coordenador!'))
          .then(() => {
            this.deleting = null;    
          });
        }
      },
      fetchLists() {
        this.loading = true;
        UserService.coordinators()
          .then((lists) => {
            this.lists = lists;
            this.loading = false;
          });
      }
    },
  }
</script>
