import http from './http';

const fetch = (id) => {
  return http.get('coordinators/fetch', {
    params: {id}
  });
};

const save = (data) => {
  return http.post('coordinators/save', data);
};

const remove = (data) => {
  return http.post('coordinators/remove', data);
};

export default { fetch, save, remove };