var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","fullscreen":"","persistent":"","transition":"dialog-bottom-transition","value":_vm.value}},[_c('validation-observer',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Coordenador")])],1)],1),_c('v-loading',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card-text',[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-avatar',{attrs:{"size":"60","color":"grey"}},[(_vm.form.profile.avatar.path)?[_c('v-img',{attrs:{"src":_vm.form.profile.avatar.path}})]:[_c('span',{staticClass:"white--text text-uppercase",style:({ fontSize: '30px' })},[_vm._v(" "+_vm._s(_vm.initials)+" ")])]],2)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('AvatarUploader',{on:{"success":_vm.onAvatarUploadSuccess}},[_vm._v(" Alterar Foto ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"hide-details":"","label":"Habilitado"},model:{value:(_vm.form.profile.active),callback:function ($$v) {_vm.$set(_vm.form.profile, "active", $$v)},expression:"form.profile.active"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"profile.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"","label":"Nome"},model:{value:(_vm.form.profile.name),callback:function ($$v) {_vm.$set(_vm.form.profile, "name", $$v)},expression:"form.profile.name"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"profile.gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","label":"Gênero","items":_vm.genderLists},model:{value:(_vm.form.profile.gender),callback:function ($$v) {_vm.$set(_vm.form.profile, "gender", $$v)},expression:"form.profile.gender"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"profile.document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"","type":"text","label":"CPF"},model:{value:(_vm.form.profile.document),callback:function ($$v) {_vm.$set(_vm.form.profile, "document", $$v)},expression:"form.profile.document"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"","type":"email","label":"E-mail"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"","obscore":"","label":"Senha","type":_vm.typePassword,"append-icon":_vm.iconPassword},on:{"click:append":_vm.toggleShowPassword},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"tertiary"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onClose}},[_vm._v("Fechar")]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","disabled":_vm.saving,"loading":_vm.saving,"type":"submit","color":"primary"}},[_vm._v("Salvar")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }